import { useSignTypedData } from "wagmi"
import type { BigNumber, BigNumberish } from "@ethersproject/bignumber"
import { ethers } from "ethers"

import { useConnector } from "web3/hooks/useConnector"
import { getErrorMessage } from "common/helpers/error"
import { useToast } from "common/hooks/useToast"
import {
  getSignDomain as getDelegateSignDomain,
  getSignTypes as getDelegateSignTypes,
  getSignValue as getDelegateSignValue,
} from "delegation/constants/signed-type"
import {
  getSignDomain as getVotingSignDomain,
  getSignTypes as getVotingSignTypes,
  getSignValue as getVotingSignValue,
} from "voting/constants/signed-type"
import type { GovernorType } from "query/graphql"

export function useSignTransaction({
  chainId,
}: {
  chainId: number | undefined
}) {
  const { toast } = useToast()
  const { isWalletConnect } = useConnector()

  const { signTypedDataAsync } = useSignTypedData()

  const normalizeSignature = (signature: string): string => {
    const { v, r, s } = ethers.utils.splitSignature(signature)
    const normalizedV = v < 27 ? v + 27 : v

    return ethers.utils.joinSignature({ r, s, v: normalizedV })
  }

  const signDelegateTransaction = async ({
    contractAddress,
    contractName,
    delegateeAddress,
    nonce,
    expiry,
    governorType,
  }: {
    contractAddress: string
    contractName: string
    delegateeAddress: string
    nonce: BigNumber
    expiry: number
    governorType: GovernorType
  }) => {
    try {
      const domain = getDelegateSignDomain({
        contractName,
        chainId,
        contractAddress,
        governorType,
      })
      const types = getDelegateSignTypes({ governorType })
      const value = getDelegateSignValue({
        delegatee: delegateeAddress,
        nonce: nonce.toNumber(),
        expiry: ethers.BigNumber.from(expiry),
      })

      const signature = await signTypedDataAsync({
        domain,
        types,
        value,
      })

      return signature ?? undefined
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      toast({
        status: "warning",
        title: "Sign transaction canceled",
        description: errorMessage,
      })

      return undefined
    }
  }

  const signCastVoteTransaction = async ({
    contractAddress,
    contractName,
    proposalId,
    support,
    governorType,
  }: {
    contractAddress: string
    contractName: string
    proposalId: BigNumberish
    support: number
    governorType: GovernorType
  }) => {
    try {
      const domain = getVotingSignDomain({
        contractName,
        chainId,
        contractAddress,
        governorType,
      })
      const types = getVotingSignTypes({ governorType })
      const value = getVotingSignValue({
        proposalId,
        support,
      })

      const signature = await signTypedDataAsync({
        domain,
        types,
        value,
      })

      return signature ?? undefined
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      toast({
        status: "warning",
        title: "Sign transaction canceled",
        description: errorMessage,
      })

      return undefined
    }
  }

  const signAddContenderMessage = async ({
    electionContract,
    proposalId,
    isTest = false,
  }: {
    proposalId: number | string
    electionContract: string
    isTest?: boolean
  }) => {
    const domain = {
      name: "SecurityCouncilNomineeElectionGovernor",
      version: "1",
      chainId: isTest ? 421614 : chainId,
      verifyingContract: electionContract as `0x${string}`,
    }

    const types = {
      AddContenderMessage: [{ name: "proposalId", type: "uint256" }],
    }

    const message = {
      proposalId: String(proposalId),
    }

    if (isWalletConnect) {
      alert(
        "The signature request will be sent to the connected wallet via WalletConnect",
      )
    }

    try {
      const signature = await signTypedDataAsync({
        domain,
        types,
        value: message,
      })

      if (!signature) return undefined

      const normalizedSignature = normalizeSignature(signature)

      const signatureBytes = ethers.utils.arrayify(normalizedSignature)

      return signatureBytes
    } catch (error) {
      console.log("debug - Error signing message:", error)

      return undefined
    }
  }

  return {
    signDelegateTransaction,
    signCastVoteTransaction,
    signAddContenderMessage,
  }
}
