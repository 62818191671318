import { Button, Flex, Box, Text, Input } from "@chakra-ui/react"
import type { PublicKey } from "@solana/web3.js"
import { Field, type FieldProps, Form, Formik } from "formik"
import * as Yup from "yup"

type StakeAccountTransferTokensProps = {
  balance: number
  stakeAccount: PublicKey | null
  handleSubmit: (values: {
    amount: string
  }) => Promise<void | string | undefined>
  handleNumberInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => void
  handleMax: (
    balance: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => void
  includeSubmit?: boolean
  includeDescription?: boolean
  submitElement?:
    | React.ReactElement
    | null
    | ((args: { isValid: boolean }) => React.ReactElement)
}

const validationSchema = (balance: number) =>
  Yup.object({
    amount: Yup.string()
      .matches(/^\d*\.?\d*$/, "Must be a valid number")
      .required("Required")
      .test(
        "balanceExceeded",
        "Amount cannot exceed balance",
        (amount) => Number(amount) <= balance,
      ),
  })

const StakeAccountTransferTokens = ({
  balance,
  stakeAccount,
  handleSubmit,
  handleNumberInput,
  handleMax,
  includeSubmit = true,
  includeDescription = true,
  submitElement = null,
}: StakeAccountTransferTokensProps) => (
  <Formik
    initialValues={{ amount: "0" }}
    validationSchema={validationSchema(balance)}
    onSubmit={handleSubmit}
  >
    {({ handleSubmit, setFieldValue, touched, errors, isValid, values }) => (
      <Form onSubmit={handleSubmit}>
        <Flex borderRadius="lg" borderWidth={1} p={4}>
          <Flex alignItems="center" w="full">
            <Box flex={1}>
              <Text color="gray.700" fontWeight="medium" mb={1}>
                W tokens
              </Text>
              <Field name="amount">
                {({ field }: FieldProps) => (
                  <Input
                    {...field}
                    _focus={{ boxShadow: "none" }}
                    aria-label="Token value"
                    border="none"
                    fontSize="2xl"
                    fontWeight="bold"
                    height="auto"
                    isDisabled={!balance}
                    p={0}
                    width="full"
                    onChange={(e) => handleNumberInput(e, setFieldValue)}
                    onFocus={() => {
                      if (values.amount === "0") {
                        setFieldValue("amount", "")
                      }
                    }}
                  />
                )}
              </Field>
              {touched.amount && errors.amount ? (
                <Text color="red.500" fontSize="sm">
                  {errors.amount}
                </Text>
              ) : null}
            </Box>
            <Button
              isDisabled={!balance}
              ml={4}
              variant="solid"
              onClick={() => handleMax(balance, setFieldValue)}
            >
              MAX
            </Button>
          </Flex>
        </Flex>
        {includeDescription ? (
          <Text color="gray.500" fontSize="sm" my={2}>
            You can deposit any amount. Tokens in your stake account count
            towards your voting power.
          </Text>
        ) : null}
        {!stakeAccount ? (
          <>
            {includeSubmit ? (
              <Button
                alignSelf="flex-start"
                isDisabled={!balance || !isValid || !Number(values.amount)}
                type="submit"
                variant="secondary"
              >
                Create and deposit
              </Button>
            ) : null}
          </>
        ) : (
          <>
            {includeSubmit ? (
              <Button
                alignSelf="flex-start"
                isDisabled={!balance || !isValid || !Number(values.amount)}
                type="submit"
                variant="secondary"
              >
                Deposit tokens
              </Button>
            ) : null}
            {typeof submitElement === "function"
              ? submitElement({ isValid })
              : submitElement}
          </>
        )}
      </Form>
    )}
  </Formik>
)

export default StakeAccountTransferTokens
