import { useEffect, useState } from "react"
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react"
import { getAccount } from "@solana/spl-token"
import { useConnection } from "@solana/wallet-adapter-react"
import { BN } from "bn.js"

import Link from "common/components/Link"
import StakeAccountTransferTokens from "web3/solana/wormhole-staking/StakeAccountTransferTokens"
import { useStakeAccount } from "web3/solana/wormhole-staking/StakeAccountProvider"
import { WHTokenBalance } from "web3/solana/wormhole-staking/WHTokenBalance"
import { EXTERNAL_ROUTES } from "common/constants/routes"

type StakeAccountInterceptProps = {
  isOpen: boolean
  onClose: () => void
  voting?: boolean
}

const StakeAccountIntercept = ({
  isOpen,
  onClose,
  voting = false,
}: StakeAccountInterceptProps) => {
  const {
    balance,
    stakeAccount,
    stakeAccountCustodyAddress,
    createStakeAccount,
    createStakeAccountAndTransfer,
    transferToStakingAccount,
  } = useStakeAccount()
  const { connection } = useConnection()

  const [custodyBalance, setCustodyBalance] = useState("")
  const [value, setValue] = useState("create-deposit")

  function handleNumberInput(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) {
    const value = e.target.value

    if (value === "" || /^\d*\.?\d+$|^\d+\.?\d*$/.test(value)) {
      setFieldValue("amount", value)
    }
  }

  function handleRadioChange(val: string) {
    setValue(val)
  }

  function handleMax(
    balance: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) {
    setFieldValue("amount", `${balance}`)
  }

  async function handleSubmit(values: { amount: string }) {
    if (stakeAccount) {
      await handleTransferToStakingAccount(values.amount)
    } else {
      await createStakeAccountAndTransfer(values.amount)
    }
  }

  async function handleTransferToStakingAccount(amount: string) {
    if (!amount) {
      return
    }

    const parsedAmount = parseFloat(amount)

    if (!amount || isNaN(parsedAmount) || parsedAmount <= 0) {
      return
    }

    return transferToStakingAccount(amount)
  }

  useEffect(() => {
    async function getCustodyDetails() {
      if (!stakeAccountCustodyAddress) {
        return
      }

      try {
        const custody = await getAccount(connection, stakeAccountCustodyAddress)

        const wh = new WHTokenBalance(new BN(custody.amount.toString()))

        setCustodyBalance(wh.toString())
      } catch {
        setCustodyBalance("")
      }
    }

    getCustodyDetails()
  }, [stakeAccountCustodyAddress, connection])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent top={{ base: "unset", lg: "120px" }}>
        <ModalHeader
          borderBottomColor="gray.100"
          borderBottomWidth={1}
          p={4}
          textStyle="h5"
        >
          Stake account required
          <ModalCloseButton right={4} top={4} />
        </ModalHeader>
        <ModalBody>
          <Stack py={2} spacing={4}>
            <Stack spacing={4}>
              {!voting ? (
                <Text color="gray.700">
                  You must create a stake account and fund it before you can
                  delegate
                </Text>
              ) : (
                <Text color="gray.700">
                  You must create a stake account and optionally fund it before
                  you can vote
                </Text>
              )}
              <Stack borderRadius="lg" borderWidth={1} px={2} py={4}>
                <HStack justifyContent="space-between">
                  <Text>Your wallet balance:</Text>
                  <Text>{balance ?? "0"} W</Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>Stake account balance:</Text>
                  <Text>
                    {!!custodyBalance ? custodyBalance : "Not created yet"}
                  </Text>
                </HStack>
              </Stack>
              <RadioGroup value={value} onChange={handleRadioChange}>
                <Radio value="create-deposit">Create and deposit</Radio>
                <Radio marginLeft={4} value="create">
                  Create only
                </Radio>
              </RadioGroup>
              {value === "create-deposit" ? (
                <StakeAccountTransferTokens
                  balance={balance}
                  handleMax={handleMax}
                  handleNumberInput={handleNumberInput}
                  handleSubmit={handleSubmit}
                  stakeAccount={stakeAccount}
                />
              ) : null}
              {value === "create" ? (
                <Stack>
                  <Button
                    alignSelf="flex-start"
                    variant="secondary"
                    onClick={createStakeAccount}
                  >
                    Create stake account only
                  </Button>
                </Stack>
              ) : null}
              <Link
                isExternal
                className="no-underline"
                color="primary.600"
                href={EXTERNAL_ROUTES.multiGov.learnMoreAboutStakeAccounts}
              >
                Learn more about stake accounts
              </Link>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default StakeAccountIntercept
