import { useMemo, useState } from "react"
import { useBalance } from "wagmi"

import { ChainId } from "web3/constants/chains"
import { getChain } from "web3/helpers/chains"
import { chainReferenceToChainId } from "web3/helpers/transformers"
import { useAddress } from "web3/hooks/useAddress"

type Values = {
  hasBalance: boolean
  isBalanceRequired: boolean
  skipBalanceCheck: () => void
}

type Props = {
  chainReference?: number
}

const REQUIRED_BALANCE_CHAIN_IDS = [
  ChainId.FilecoinMainnet.toString(),
  ChainId.FilecoinCalibrationTestnet.toString(),
]

export const useAccountBalanceCheck = ({ chainReference }: Props): Values => {
  const address = useAddress()

  const [shouldSkipBalanceCheck, setShouldSkipBalanceCheck] = useState(false)

  const chain = useMemo(() => {
    if (!chainReference) return null

    const chainId = chainReferenceToChainId(chainReference)

    if (!chainId) return null

    return getChain(chainId)
  }, [chainReference])

  const isBalanceRequired = chain
    ? REQUIRED_BALANCE_CHAIN_IDS.includes(chain.id)
    : false

  const { data: nativeBalanceData } = useBalance({
    address:
      isBalanceRequired && address ? (address as `0x${string}`) : undefined,
    chainId: chainReference ?? undefined,
  })

  const skipBalanceCheck = () => {
    setShouldSkipBalanceCheck(true)
  }

  return {
    hasBalance: nativeBalanceData?.formatted !== "0.0",
    isBalanceRequired: shouldSkipBalanceCheck ? false : isBalanceRequired,
    skipBalanceCheck,
  }
}
